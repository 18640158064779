.profile-form-container {
    max-width: 100%;
    padding: 2rem;
}

.p-field {
    margin-top: 1.5rem;
}

/* Tamanho dos campos em desktop */
@media (min-width: 800px) {
    .p-field {
        width: 100%; /* Ou ajuste para o tamanho desejado */
    }

    .p-float-label > input {
        width: 100%; /* Campos maiores em desktop */
    }
}

/* Ajuste para mobile */
@media (max-width: 767px) {
    .p-field {
        width: 100%; /* Campos ocupam 100% da largura em mobile */
    }

    .p-float-label > input {
        width: 100%; /* Campos ajustam para 100% em mobile */
    }
}

.custom-dialog {
    width: 90vw; /* 90% da largura da viewport no mobile */
    height: auto; /* Altura ajusta automaticamente ao conteúdo */
    max-width: 90vw;
}

@media (min-width: 600px) {
    .custom-dialog {
        width: 600px; /* Limita o tamanho máximo a 600px no desktop */
        height: auto; /* Ajusta a altura automaticamente no desktop */
        max-width: 600px;
    }
}

.floating-button {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
    color: white;
    border: none;
    width: 60px;
    height: 60px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

/* Adicione espaço no final do conteúdo para que o botão não sobreponha */
.page-content {
    padding-bottom: 100px; /* O valor deve ser um pouco maior que a altura do botão */
}

.floating-button:hover {
    cursor: pointer;
}

.error-field {
    border: 2px solid red !important;
}


/* Efeito de girar quando passar o mouse ou tocar */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Efeito de fade quando passar o mouse ou tocar */
@keyframes fade {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.floating-button {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Girar e fade no hover (mouse) ou focus (toque) */
.floating-button:hover, .floating-button:focus {
    animation: rotate 0.5s ease-in-out forwards, fade 0.5s ease-in-out forwards;
    opacity: 1;
}
/* Efeito de girar no hover */
.floating-button:hover, .floating-button:focus {
    transform: rotate(360deg);
    opacity: 1;
}

/* Animação de transição para suavizar os efeitos */
.floating-button {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.8; /* Valor inicial de opacidade */
}

/* Girar e fade no hover (mouse) ou focus (toque) */
.floating-button:hover {
    transform: rotate(360deg); /* Gira o botão 360 graus */
    opacity: 1; /* Aumenta a opacidade para 100% */
}
/* adminmenu.css */
.menu-items-section .login-container {
    padding: 20px;
}

.menu-items-section .register-input {
    width: 100%;
    margin-bottom: 1rem;
}

.menu-items-section .added-items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.menu-items-section .category-card {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    background-color: #f9f9f9;
    margin-bottom: 0.5em;
}

.menu-items-section .category-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.menu-items-section .item-list {
    padding-left: 0;
    list-style-type: none;
}

.menu-items-section .item {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
}

.menu-items-section .item-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

.menu-items-section .item-name {
    font-weight: bold;
    white-space: nowrap;
}

.menu-items-section .item-dots {
    flex-grow: 1;
    border-bottom: 1px dotted #ccc;
    margin: 0 10px;
}

.menu-items-section .item-price {
    font-weight: bold;
    white-space: nowrap;
}

.menu-items-section .item-description {
    margin-top: 5px;
    font-style: italic;
    text-align: left;
}

.menu-items-section .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.menu-items-section .highlight-checkbox {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.menu-items-section .highlight-checkbox label {
    margin-left: 8px;
}

.custom-tab {
    background-color: #f0f0f0; 
    color: #333333; 
}

/* Efeito de girar quando passar o mouse ou tocar */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Efeito de fade quando passar o mouse ou tocar */
@keyframes fade {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}



/* Girar e fade no hover (mouse) ou focus (toque) */
.floating-button:hover, .floating-button:focus {
    animation: rotate 0.5s ease-in-out forwards, fade 0.5s ease-in-out forwards;
    opacity: 1;
}
/* Efeito de girar no hover */
.floating-button:hover, .floating-button:focus {
    transform: rotate(360deg);
    opacity: 1;
}


.floating-button {
    position: fixed;  /* Permite que os botões fiquem fixos em relação à janela */
    bottom: 20px;     /* Distância do fundo */
    right: 20px;      /* Distância da direita */
    z-index: 9999;    /* Valor alto para sobrepor outros elementos */
    border-radius: 50%; /* Formato redondo */
    width: 50px;      /* Largura do botão */
    height: 50px;     /* Altura do botão */
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;     /* Opacidade inicial */
}

/* Efeito ao passar o mouse */
.floating-button:hover {
    transform: rotate(360deg); /* Girar o botão */
    opacity: 1; /* Aumenta a opacidade */
}

.banner {
    text-align: center;
    margin-bottom: 0.8rem;
    padding: 0;
    background-size: cover;
    background-position: center;
    height: 10vh;  
    min-height: 200px;  
    max-height: 400px; 
    background-image: url('../../images/bannercdc.png'); 
}

@media (max-width: 768px) { /* Altere 768px para o tamanho desejado */
    .banner {
        max-height: 80px; /* Defina a altura máxima para dispositivos móveis */
        height: 20vh; /* Ajuste a altura em viewport units */
        min-height: 100px; /* Altura mínima para evitar banners muito pequenos */
    }
}

/* Estilos gerais */
.profile-form-container {
    max-width: 100%;
    padding: 2rem;
}

.p-field {
    margin-top: 1.5rem;
}

/* Tamanho dos campos em desktop */
@media (min-width: 800px) {
    .p-field,
    .p-float-label > input {
        width: 100%; /* Campos ocupam 100% da largura */
    }
}

/* Ajuste para mobile */
@media (max-width: 767px) {
    .p-field,
    .p-float-label > input {
        width: auto; /* Campos ajustam para o tamanho natural em mobile */
    }
}

/* Diálogo personalizado */
.custom-dialog {
    width: 90vw; /* 90% da largura da viewport no mobile */
    height: auto; /* Altura ajusta automaticamente ao conteúdo */
    max-width: 90vw;
}

@media (min-width: 600px) {
    .custom-dialog {
        width: 600px; /* Limita o tamanho máximo a 600px no desktop */
        height: auto; /* Ajusta a altura automaticamente no desktop */
        max-width: 600px;
    }
}

/* Botão flutuante */
.floating-button {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
    border-radius: 50%;
    width: 60px; /* Ajuste de largura */
    height: 60px; /* Ajuste de altura */
    color: white;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.8; /* Valor inicial de opacidade */
}

.page-content {
    padding-bottom: 100px; /* O valor deve ser um pouco maior que a altura do botão */
}

.floating-button:hover {
    cursor: pointer;
    transform: rotate(360deg); /* Gira o botão 360 graus */
    opacity: 1; /* Aumenta a opacidade para 100% */
}

/* Campo de erro */
.error-field {
    border: 2px solid red !important;
}

/* Estilos de animação */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Estilos do MenuTable */
.menu-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem; /* Espaço entre os itens */
    padding: 1rem;
}

/* Cartão de menu */
.menu-card {
    border: 1px solid #ddd; /* Borda ao redor do cartão */
    border-radius: 8px; /* Bordas arredondadas */
    padding: 1rem; /* Espaçamento interno */
    text-align: center; /* Centraliza o texto */
    background-color: #fff; /* Fundo branco */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra leve */
    transition: transform 0.3s, filter 0.3s; /* Adiciona transição suave */
    max-width: 100%; /* Limita a largura máxima em dispositivos móveis */
    margin: 0 auto; /* Centraliza o cartão */
}

/* Imagem do menu */
.menu-image {
    width: 100%;
    height: auto;
    max-width: 150px; /* Limita a largura máxima */
    object-fit: cover; /* Mantém a proporção da imagem */
    margin-bottom: 0.5rem;
}

/* Grupo de botões */
.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espaço entre os botões */
    width: 100%; /* Tamanho total do card */
}

/* Estilos adicionais para telas pequenas */
@media (max-width: 600px) {
    .menu-grid-container {
        grid-template-columns: repeat(2, 1fr); /* Garante que haja 2 colunas em telas pequenas */
    }
}

@media (max-width: 768px) {
    .menu-grid-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 0.5rem;
    }

    .menu-card {
        padding: 0.5rem;
        max-width: 90%; /* Impede que o card fique esticado em mobile */
        margin: 0 auto; /* Centraliza o card */
    }

    .menu-name {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .menu-grid-container {
        grid-template-columns: 1fr; /* Uma coluna em telas muito pequenas */
    }

    .menu-card {
        max-width: 90%; /* Limita a largura máxima em dispositivos móveis */
        padding: 0.5rem;
        margin: 0 auto; /* Centraliza o card */
    }

    .menu-name {
        font-size: 0.9em;
    }
}

/* Estilos para o cartão de menu na interação */
.menu-card:hover {
    transform: scale(1.05); /* Aumenta levemente o card */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Adiciona sombra ao card */
}

.menu-card.inactive {
    background-color: #f0f0f0; /* Fundo cinza */
   
}

.menu-name {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
}

/* Efeitos de sobreposição */
.image-container {
    position: relative;
    width: 100%;
    height: 150px; /* Ajuste a altura para mais destaque */
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ao redor */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 10px;
}



.menu-card .button-group .exibir-button {
    background-color: #007bff !important;
    color: #ffffff !important;
    border-color: #007bff !important;
}

.profile-form-container {
    max-width: 100%;
    padding: 2rem;
}

.p-field {
    margin-top: 1.5rem;
}


.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Garante que o contêiner ocupe a altura total da viewport */
}



/* Adicione espaço no final do conteúdo para que o botão não sobreponha */
.page-content {
    padding-bottom: 100px; /* O valor deve ser um pouco maior que a altura do botão */
}

.floating-button:hover {
    cursor: pointer;
}

.item-list {
    padding-left: 0;
    list-style-type: none;
}


.item-info {
    margin-top: 5px;
    margin-left: 5px;
    text-align: left;
}
.highlight-checkbox {
    display: flex;
    align-items: center; /* Alinha o checkbox e o label verticalmente */
    margin: 10px 0; /* Espaçamento vertical */
}

.highlight-checkbox label {
    margin-left: 8px; /* Ajusta o espaço entre o checkbox e o label */
}

.custom-dialog {
    width: 90vw; /* 90% da largura da viewport no mobile */
    height: auto; /* Altura ajusta automaticamente ao conteúdo */
    max-width: 90vw;
}

@media (min-width: 600px) {
    .custom-dialog {
        width: 600px; /* Limita o tamanho máximo a 600px no desktop */
        height: auto; /* Ajusta a altura automaticamente no desktop */
        max-width: 600px;
    }
}